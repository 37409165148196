import type { IncomingMessage } from "http";
import { Redirect } from "next";
import { Session } from "next-auth";
import { log } from "@pam/common/base-logger";
import { getSession } from "next-auth/react";

export const LOGIN_HINT_PARAMETER_NAME = "login_hint";

export function getBase64DecodedLoginHintFromUrl(url: string): string {
  const urlObj = new URL(url);
  // searchParams automatically decodes whatever parameters exist in the given url
  const loginHintParam = urlObj.searchParams.get(LOGIN_HINT_PARAMETER_NAME);
  return loginHintParam === null ? "" : Buffer.from(loginHintParam, "base64").toString("ascii");
}

export async function userHasAccounts(req: IncomingMessage): Promise<boolean> {
  const session = await getSession({ req });

  if (!session) return false;

  if (!session.user.idTokenAccountRoles) return false;

  return session.user.idTokenAccountRoles.length > 0;
}

/**
 * This serves primarily as our authentication for pages/routes. We handle the following cases:
 * 1. Checking for authentication
 * 2. If no authentication, redirect to sign in
 * 3. If authentication is present, but the current logged in user doesn't match a given login hint (used for automatic oauth signins),
 * then we sign the user out automatically, and finally redirect the new user to sign in.
 */
export async function checkAuthentication(
  req: IncomingMessage,
  url?: string
): Promise<{ redirect: Redirect } | Session> {
  log.debug({ requestUrl: req.url, url }, "Checking authentication");

  // Use the request URL by default
  let callbackUrl = `${process.env.NEXTAUTH_URL as string}${req.url || ""}`;
  // If the request URL contains an internal Next.js data url, use the resolved URL from Next.js instead
  // The resolved URL does not contain any path parameters used during the rewrite setup in next.config.js
  if (req.url?.includes("/_next/data/")) {
    log.info("Request URL contains /_next/data/, instead using the Next.js resolved URL");
    callbackUrl = `${process.env.NEXTAUTH_URL as string}${url || ""}`;
  }

  const session = await getSession({ req });

  if (!session) {
    const uri = `/api/auth/signin/bcc-login-app?callbackUrl=${encodeURIComponent(callbackUrl)}`;
    log.info({ uri }, "Not logged in, attempting to login");

    return {
      redirect: {
        destination: uri,
        permanent: false,
      },
    };
  }

  if (!(await userHasAccounts(req))) {
    log.warn(
      {
        user: session.user.email,
        default_account: session.user.defaultAccount,
        accounts: session.user.idTokenAccountRoles,
      },
      "User doesn't have accounts, redirecting to sign out and go to account not set up error page"
    );

    const signoutCallbackUrl = `${process.env.NEXTAUTH_URL as string}/auth/error-accounts-not-setup`;

    // We need to sign the user out, then display the error page so any existing account data is cleared out
    return {
      redirect: {
        destination: `/auth/signout?callbackUrl=${encodeURIComponent(signoutCallbackUrl)}`,
        permanent: false,
      },
    };
  }

  if (url) {
    const decodedLoginHint = getBase64DecodedLoginHintFromUrl(`${process.env.NEXTAUTH_URL as string}/${url}`);
    if (session && decodedLoginHint && session.user.email !== decodedLoginHint) {
      const uri = `/auth/signout?callbackUrl=${encodeURIComponent(callbackUrl)}`;
      log.info(
        { newUser: decodedLoginHint, existingUser: session.user.email, uri },
        "New user doesn't match existing logged in user, redirecting to logout"
      );

      return {
        redirect: {
          destination: uri,
          permanent: false,
        },
      };
    }
  }

  return session;
}
